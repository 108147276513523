@import '~styles/base';

:global(.theme--light) {
  background-color: $grey-100;
  color: $grey-900;
  min-height: 100dvh;
}

:global(.theme--dark) {
  background-color: $grey-900;
  color: $grey-100;
  min-height: 100dvh;
}
