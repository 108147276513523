/*
Variables
=========
*/

$radius-xs: 2px;
$radius: 3px;
$radius-s: 4px;
$radius-m: 6px;
$radius-lg: 8px;
$radius-xl: 10px;
$radius-2xl: 12px;
$radius-3xl: 16px;
$radius-4xl: 20px;
$radius-5xl: 24px;
$radius-6xl: 60px;
$radius-7xl: 120px;
$radius-round: 50%;

$shadow-focus: 0px 0px 2px 2px $blue-200;

$box-shadow: 0px 1px 4px rgba($grey-900, 0.2); // $box-shadow;

$over-under-button-wrapper-width: 196px;

// See utilities/constants.ts if this needs to be updated
$cdn-host: 'https://assets.underdogfantasy.com/web';
