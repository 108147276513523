@import '~styles/base';

.errorFallback {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: $grey-900;
  flex-direction: column;
  text-align: center;
  gap: space(1.5);
}

.title {
  @include text('l');
  color: $grey-100;
  font-family: $roboto-condensed;
}

.message {
  @include text('s');

  // The background color of the error fallback screen is a fixed value,
  // it is not themed. Therefore, we don't want use a themed color value
  // here otherwise it may not be visible for users with light theme.
  color: $grey-100;

  // Similar to above, we don't want to use a themed color value for a
  // button element that is nested in `.message` text.
  // We also need to set it to `!important` to override the default
  // styling for the button / SupportLink component.
  button {
    color: $grey-100 !important;
    &:hover {
      color: $grey-100 !important;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}
