/*
Typography
==========
*/

// For font famlies see typography.global

$roboto-condensed: 'roboto-condensed', 'roboto', sans-serif;
$roboto: 'roboto', sans-serif;
$roboto-slab: 'roboto-slab', san-serif;

/*
Text Sizing
===========
*/

$text-sizes: (
  'mini': (
    'size': 10,
    'line': 16,
  ),
  'mini-low': (
    'size': 10,
    'line': 12,
  ),
  'xxs': (
    'size': 12,
    'line': 24,
  ),
  'xxs-low': (
    'size': 12,
    'line': 16,
  ),
  'xs': (
    'size': 14,
    'line': 16,
  ),
  'xs-high': (
    'size': 14,
    'line': 20,
  ),
  's': (
    'size': 16,
    'line': 24,
  ),
  's-low': (
    'size': 16,
    'line': 16,
  ),
  'm': (
    'size': 20,
    'line': 24,
  ),
  'l': (
    'size': 24,
    'line': 24,
  ),
  'l-high': (
    'size': 24,
    'line': 32,
  ),
  'xl': (
    'size': 36,
    'line': 36,
  ),
  'xl-high': (
    'size': 36,
    'line': 48,
  ),
  'xxl': (
    'size': 64,
    'line': 72,
  ),
  'xxl-low': (
    'size': 64,
    'line': 56
  ),
);

@function fontSize($size) {
  $sizeMap: map-get($text-sizes, $size);
  @return map-get($sizeMap, 'size') + px;
}

@function lineHeight($size) {
  $sizeMap: map-get($text-sizes, $size);
  @return map-get($sizeMap, 'line') + px;
}

@mixin text($size) {
  font-size: fontSize($size);
  line-height: lineHeight($size);
}
