/*
Colors
======
*/

/**
Shades of grey
*/

$white-0: #ffffff;

// greys

$grey-100: #f0f0f0;
$grey-150: #f7f7f7;
$grey-200: #e4e4e4;
$grey-300: #d8d8d8;
$grey-400: #c4c4c4;
$grey-500: #a0a0a0;
$grey-600: #6b6b6b;
$grey-650: #5a5a5a;
$grey-700: #393939;
$grey-900: #1b1b1b;

$black-0: #000000;

$transparent-black: rgb(0 0 0 / 16%);
$transparent-black-dark: rgb(0 0 0 / 64%);

/**
Brand colors
*/

// brand colors
$green-50: #11c02b;
$green-100: #0ea023;
$green-200: #1ca530;
$green-300: #139449;
$green-500: #579972;
$green-600: #0e6e36;
$red-200: #e33c31;
$red-400: #c02419;
$gold-50: #ffd114;
$gold-75: #ffc627;
$gold-100: #e0b400;
$gold: #ffce00;
$gold-200: #ad8b00;

// defaults
$primary: $gold;

$info-light: #1356b6;
$info-dark: #75bdff;
$info-subtle-light: #d6ebff;
$info-subtle-dark: #072145;
$link: #0a6abd;
$success-subtle-light: #d9e8db;
$success-subtle-dark: #1a2c1d;
$error-subtle-light: #efdcdc;
$error-subtle-dark: #391b19;
$feedback-warning-light: #ad5300;
$feedback-warning-dark: #ffad61;
$feedback-subtle-light: #fdeee0;
$feedback-subtle-dark: #ad5300;
$alert-error: #e15547;
$feedback-brand-subtle: #fff9e3;

/**
Sport Colors
*/

$asg: #fa6705;
$atp: #e6f53c;
$boxing: #d0402e;
$cbb: #fa6705;
$cfb: #175fc7;
$epl: #59025f;
$esports: #b40dbf;
$hotdog: #c57b27;
$mlb: #d0402e;
$mma: #0ea023;
$nba: #fa6705;
$nfl: #175fc7;
$nhl: #0fc8d4;
$pga: #627a09;
$racing: #e83c24;
$soccer: #b40dbf;
$usfl: #175fc7;
$wnba: #fa6705;
$wtp: #e6f53c;

/**
Position Colors
*/

$nfl-qb: #9647b8;
$nfl-rb: #15997d;
$nfl-wr: #e67d23;
$nfl-te: #297fb9;

$nhl-c: #9647b8;
$nhl-w: #15997d;
$nhl-d: #e67d23;
$nhl-g: #297fb9;

$nba-g: #9647b8;
$nba-w: #15997d;
$nba-b: #e67d23;

$mlb-p: #9647b8;
$mlb-if: #15997d;
$mlb-of: #e67d23;

$flex: #c8384a;
$sflex: #c73893;
$bench: $grey-500;

/**
Badge Colors
*/

$admin: #dd30a9;
$autopilot: $green-100;
$battle-royale: $gold-100;
$beginner: #2980b9;
$best-ball: #175fc7;
$expert: #ce2d27;
$guaranteed: #6cbe45;
$multi-entry: #e67e22;
$private-draft: #2980b9;
$slow: #e67e22;
$tournament: $gold-100;
$instant: #b40dbf;
$weekly-winner: #9c005e;
$scorcher: #e3510e;

/**
Medal Colors
*/

$silver-light: $grey-600;
$silver-dark: $grey-300;

$bronze-light: #83624f;
$bronze-dark: #d28857;

/**
Other
*/

$blue-100: #75bdff;
$blue-200: #3996e4;
$blue-300: #1356b6;

/**
* Theme colours
===============
* These colors for the light mode match the variables, at present the inverse of a colour is
* always the same, so when styling, use the light mode colour and then you should just always
* get the correct dark colour
*/

$themes: (
  light: (
    white-0: $white-0,
    grey-100: $grey-100,
    grey-150: $grey-150,
    grey-200: $grey-200,
    grey-300: $grey-300,
    grey-400: $grey-400,
    grey-500: $grey-500,
    grey-600: $grey-600,
    grey-650: $grey-650,
    grey-700: $grey-700,
    grey-900: $grey-900,
    black-0: $black-0,
    silver-light: $silver-light,
    bronze-light: $bronze-light,
    silver-dark: $silver-dark,
    bronze-dark: $bronze-dark,
    green-100: $green-100,
    green-500: $green-500,
    red-400: $red-400,
    blue-300: $blue-300,
    info-light: $info-light,
    info-subtle-light: $info-subtle-light,
    success-subtle-light: $success-subtle-light,
    success-subtle-dark: $success-subtle-dark,
    error-subtle-light: $error-subtle-light,
    gold-200: $gold-200,
    transparent-black: $transparent-black,
    feedback-warning-light: $feedback-warning-light,
    feedback-subtle-light: $feedback-subtle-light,
    link: $link,
  ),
  dark: (
    white-0: $black-0,
    grey-100: $grey-900,
    grey-150: $grey-900,
    grey-200: $grey-700,
    grey-300: $grey-600,
    // Might have to revisit this once varsity is up
    // so the opposites match.
    grey-400: $grey-600,
    grey-500: $grey-500,
    grey-600: $grey-300,
    grey-650: $grey-400,
    grey-700: $grey-200,
    grey-900: $grey-100,
    black-0: $white-0,
    silver-light: $silver-dark,
    bronze-light: $bronze-dark,
    silver-dark: $silver-light,
    bronze-dark: $bronze-light,
    green-100: $green-50,
    green-500: $green-200,
    red-400: $red-200,
    blue-300: $blue-100,
    info-light: $info-dark,
    info-subtle-light: $info-subtle-dark,
    success-subtle-light: $success-subtle-dark,
    success-subtle-dark: $grey-100,
    error-subtle-light: $error-subtle-dark,
    gold-200: $gold-100,
    transparent-black: $transparent-black-dark,
    feedback-warning-light: $feedback-warning-dark,
    feedback-subtle-light: $feedback-subtle-dark,
    link: $white-0,
  ),
);

/**
Theme functions
*/

@mixin themed() {
  @each $theme, $map in $themes {
    :global(.theme--#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
