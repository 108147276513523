@import '~styles/base';

.button {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: $radius;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-family: $roboto;
  font-weight: 700;
  justify-content: center;
  line-height: normal;
  margin: 0;
  padding: 0;
  overflow: visible;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  width: auto;

  &:focus {
    box-shadow: $shadow-focus;
    outline: none;
  }

  &:disabled {
    cursor: unset;
  }
}

.large {
  @include text('m');
  min-height: space(5);
  .icon {
    svg {
      height: space(3);
      width: space(3);
    }
  }
}

.regular {
  @include text('m');
  min-height: space(4);
  .icon {
    svg {
      height: space(2.5);
      width: space(2.5);
    }
  }
}

.small {
  @include text('m');
  min-height: space(3);
  .icon {
    svg {
      height: space(2);
      width: space(2);
    }
  }
}

.solid {
  border: none;
  &.green {
    @include themed() {
      background-color: t(green-100);
    }
    color: $grey-100;
    &:hover {
      background-color: $green-300;
    }
    &:active {
      background-color: $green-600;
    }
    &:disabled {
      @include themed() {
        background-color: t(grey-300);
        color: t(grey-600);
      }
      &:hover,
      &:active {
        @include themed() {
          background-color: t(grey-300);
          color: t(grey-600);
        }
      }
    }
  }
  &.grey {
    background-color: $grey-700;
    color: $grey-100;
    &:hover {
      background-color: $grey-600;
    }
    &:active {
      background-color: $grey-900;
    }
    &:disabled {
      background-color: $grey-300;
      &:hover,
      &:active {
        background-color: $grey-300;
      }
    }
  }
  &.lightgrey {
    @include themed() {
      background-color: t(grey-200);
      color: t(grey-900);
    }
    &:hover {
      @include themed() {
        background-color: lighten($grey-200, 10);
        color: $grey-900;
      }

      svg {
        @include themed() {
          fill: $grey-900;
        }
      }
    }
    &:active {
      @include themed() {
        background-color: t(grey-300);
      }
    }
    &:disabled {
      background-color: $grey-500;
      @include themed() {
        color: t(grey-900);
      }
      svg {
        @include themed() {
          fill: t(grey-900);
        }
      }
      &:hover,
      &:active {
        background-color: $grey-500;
      }
    }
    svg {
      @include themed() {
        fill: t(grey-900);
      }
    }
  }
  &.primary {
    background-color: $primary;
    position: relative;
    color: $grey-700;
    &:hover {
      background-color: lighten($primary, 10);
    }
    &:active {
      background-color: darken($primary, 10);
    }
    &:disabled {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
        pointer-events: none; /* Ensure the button stays unclickable */
        border-radius: inherit; /* Match the button's border-radius */
      }
      &:hover,
      &:active {
        background-color: $primary;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
          pointer-events: none; /* Ensure the button stays unclickable */
          border-radius: inherit; /* Match the button's border-radius */
        }
      }
    }
  }
  &.red {
    @include themed() {
      background-color: t(red-400);
    }
    color: $grey-100;
    &:hover {
      background-color: lighten($red-400, 10);
    }
    &:active {
      background-color: darken($red-400, 10);
    }
    &:disabled {
      background-color: $grey-300;
      &:hover,
      &:active {
        background-color: $grey-300;
      }
    }
  }
}

.outline {
  @include themed() {
    border: 1px solid t(grey-900);
    color: t(grey-900);
  }
  @include themed() {
    &:hover,
    &:active {
      border: 1px solid t(grey-700);
      color: t(grey-700);
    }
    &:disabled {
      border: 1px solid t(grey-500);
      color: t(grey-500);
    }
  }
}

.text {
  border-radius: 0;
  display: inline-flex;
  &.large,
  &.regular,
  &.small {
    height: initial;
    min-height: unset;
    padding: 0 space(0.25);
    &.intrinsic {
      // for specificity
      height: initial;
      min-height: unset;
      padding: 0 space(0.3);
    }
  }
  &.green {
    @include themed() {
      color: t(green-100);
    }
    .icon {
      svg {
        @include themed() {
          fill: t(green-100);
        }
        height: space(2);
        width: space(2);
      }
    }
    &:hover {
      color: $green-300;
    }
    &:active {
      color: $green-600;
    }
  }
  &.red {
    @include themed() {
      color: t(red-400);
    }
    .icon {
      svg {
        @include themed() {
          fill: t(red-400);
        }
        height: space(2);
        width: space(2);
      }
    }
    &:hover {
      color: darken($red-400, 10);
    }
    &:active {
      color: darken($red-400, 20);
    }
  }
  &.grey {
    @include themed() {
      color: t(grey-700);
    }
    .icon {
      svg {
        @include themed() {
          fill: t(grey-700);
        }
        height: space(2);
        width: space(2);
      }
    }
    &:hover {
      @include themed() {
        color: t(grey-600);
      }
      .icon {
        svg {
          @include themed() {
            fill: t(grey-600);
          }
        }
      }
    }
    &:active {
      @include themed() {
        color: t(grey-900);
      }
      .icon {
        svg {
          @include themed() {
            fill: t(grey-900);
          }
        }
      }
    }
  }
  &.primary {
    color: $primary;
    .icon {
      svg {
        fill: $primary;
        height: space(2);
        width: space(2);
      }
    }
    &:hover {
      color: lighten($primary, 10);
    }
    &:active {
      color: darken($primary, 10);
    }
  }
  &:disabled {
    @include themed() {
      color: t(grey-300);
    }
    .icon {
      svg {
        @include themed() {
          fill: t(grey-300);
        }
      }
    }
    &:hover,
    &:active {
      @include themed() {
        color: t(grey-300);
      }
      .icon {
        svg {
          @include themed() {
            fill: t(grey-300);
          }
        }
      }
    }
  }
}

.intrinsic {
  &.small {
    padding: space(0.5) space(3);
  }
  &.regular {
    padding: space(1.5) space(3);
  }
  &.large {
    padding: space(2) space(3);
  }
}

.full {
  width: 100%;
  &.large,
  &.regular,
  &.small {
    padding: space(0.5) space(3);
  }
}

.icon {
  margin-right: space(1);
}
