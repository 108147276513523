@import '~styles/base';

.icon {
  svg {
    fill: $grey-100; // if you want to theme the color for dark mode do it on the icon implementation
    height: space(3);
    width: space(3);
    display: block;
  }
}

// icons.tsx
.newBackground {
  rect {
    @include themed() {
      fill: t(grey-100);
    }
  }
}

.storybookIcon {
  svg {
    @include themed() {
      fill: t(grey-900);
    }
  }
}
